<template>
  <div class="page">
    <van-nav-bar :title="deviceData ? deviceData.deviceName : ''" fixed left-arrow @click-left="$router.back()"></van-nav-bar>

    <div style="margin-top: 50px">
      <van-cell title="选择日期区间" :value="date" @click="show = true" is-link style="margin: 8px 0" />
      <van-calendar v-model="show" type="range" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate" :default-date="defaultDate" :allow-same-day="true" />
    </div>

    <div v-if="deviceData" style="background-color: white;padding: 16px">
      <div style="display: flex;justify-content: space-evenly">
        <div style="display: flex;flex-direction: column;align-items: center;gap: 6px">
          <span style="font-size: 20px">{{ deviceData.countOrders }}</span>
          <span v-if="Number.parseFloat(deviceData.orderIncrease) >= 0" style="color: red;">同比增长{{ deviceData.orderIncrease }}</span>
          <span v-else style="color: green;">同比下降{{ deviceData.orderIncrease }}</span>
          <span style="font-weight: bold">总订单</span>
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;gap: 6px">
          <span style="font-size: 20px">{{ deviceData.usage }}</span>
          <span v-if="Number.parseFloat(deviceData.usageIncrease) >= 0" style="color: red">同比增长{{ deviceData.usageIncrease }}</span>
          <span v-else style="color: green">同比下降{{ deviceData.usageIncrease }}</span>
          <span style="font-weight: bold">使用率</span>
        </div>
      </div>
    </div>

    <v-chart class="chart" :option="option" style="background-color: white;" />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import VChart from "vue-echarts";

use([GridComponent, BarChart, CanvasRenderer]);

export default {
  name: "index",
  components: {
    VChart
  },
  data() {
    let date = new Date()
    let defaultDate = [new Date(date.getFullYear(), date.getMonth() - 1, date.getDate() + 1), new Date(date.getFullYear(), date.getMonth(), date.getDate())]
    let minDate = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    let maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    return {
      loading: false,
      finished: false,
      deviceData: null,
      date: '',
      show: false,
      form: { startDate:'', endDate:'' },
      minDate,
      maxDate,
      defaultDate: defaultDate,

      option:  {
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [],
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(220, 220, 220, 0.8)'
          }
        }],
        grid: [
          {
            height: 300,
            containLabel: true
          }
        ],
      }
    }
  },
  created() {
    this.date = `${this.formatDate(this.defaultDate[0])} - ${this.formatDate(this.defaultDate[1])}`;
    this.form.startDate = this.formatForServer(this.defaultDate[0])
    this.form.endDate = this.formatForServer(this.defaultDate[1])
    console.log(this.$route.query)
    this.form.deviceId = this.$route.query.deviceId
    this.form.partnerId = this.$route.query.partnerId
    this.form.resellerId = this.$route.query.resellerId

    this.onLoad()
  },
  methods: {
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.form.startDate = this.formatForServer(start)
      this.form.endDate = this.formatForServer(end)
      this.onLoad()
    },
    formatForServer(date) {
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      return `${year < 10 ? ('0' + year) : year}-${month < 10 ? ('0' + month) : month}-${day < 10 ? ('0' + day) : day}`
    },

    onRefresh() {
      this.onLoad()
    },
    onLoad() {
      this.$toast.loading()
      this.$http.get('data/deviceData', {params: this.form}).then(res => {
        this.$toast.clear()
        if (res.code === 200) {
          this.deviceData = res.data
          this.option.xAxis.data = res.data.overview.date.map(it => it.substr(5))
          this.option.series[0].data = res.data.overview.value
        } else {
          if (res.code === 403) {
            this.$toast.fail('登录信息过期，请重新登录')
            this.$bus.$emit('login')
          }
        }
      }).catch(() => {
        this.$toast.clear()
      })
    },

    clickCell(cabinet) {
      this.$router.push({
        path: '/cabinetCell',
        query: {cabinetId: cabinet.deviceId}
      })
    }
  }
}
</script>

<style scoped>

</style>
